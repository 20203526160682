<template>
  <div>
    <div class="tableTitle">
      <span class="title"> 基础信息 </span>
    </div>
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="产品名称" :showIcon="true" :fontColor="changeList.includes('name')"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="Boolean(postFrom.is_approved)" placeholder="请输入" class="iviewIptWidth307" v-model="postFrom.name" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="生产厂家" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <Select class="iviewIptWidth307" placeholder="请选择" v-model="postFrom.factory_id" disabled>
            <Option v-for="(item, index) in factoryList" :value="item.factory_id" :key="index">{{ item.factory_name }}</Option>
          </Select>
        </div>
        <FromLabel label="储运条件" :showIcon="true" :fontColor="changeList.includes('storage_condition')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="postFrom.storage_condition" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <!--      <div class="formLine borderTopNone">-->
      <!--        <FromLabel label="最小包装规格" :showIcon="true" :fontColor="changeList.includes('unit')"></FromLabel>-->
      <!--        <div class="formFlIpt647 fl">-->
      <!--          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="postFrom.unit" :readonly="status != 3"></i-input>-->
      <!--        </div>-->
      <!--        <FromLabel label="包装规格" :showIcon="true" :fontColor="changeList.includes('packing_size')"></FromLabel>-->
      <!--        <div class="formFrIpt fl">-->
      <!--          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="postFrom.packing_size" :readonly="status != 3"></i-input>-->
      <!--          <span class="afterWords10"> /箱</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- <div class="formLine borderTopNone">
        <FromLabel label="最小包装规格" :showIcon="true" :fontColor="changeList.includes('new_unit')"></FromLabel>
        <div class="formFlIpt647 fl">
          <InputNumber :max="999999" :min="1" :precision="0" v-model="postFrom.unit.qty" :active-change="false" placeholder="请输入" class="iviewIptWidth100 marginRight18"></InputNumber>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.unit.unit" @on-change="changeUnitUnit" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.unit.dose_unit" allow-create clearable ref="unitdose_unit" @on-open-change="openChangeUnit($event, 'unit', 'dose_unit', 'unitdose_unit')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
          <span class="spin">(单位可手动填)</span>
        </div>
        <FromLabel label="包装规格" :fontColor="changeList.includes('new_packing_size')"></FromLabel>
        <div class="formFrIpt fl">
          <InputNumber :max="999999" :min="1" :precision="0" v-model="postFrom.packing_size.qty" :active-change="false" placeholder="请输入" class="iviewIptWidth100 marginRight18"></InputNumber>
          <i-input class="iviewIptWidth100" v-model="postFrom.unit.unit" disabled></i-input>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.packing_size.dose_unit" allow-create clearable ref="packing_sizedose_unit" @on-open-change="openChangeUnit($event, 'packing_size', 'dose_unit', 'packing_sizedose_unit')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
        </div>
      </div> -->
      <div class="formLine borderTopNone">
        <FromLabel label="资料维护" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <!--          <CheckboxGroup v-model="postFrom.qualification_maintain_type">-->
          <!--            <Checkbox label="1">注册证</Checkbox>-->
          <!--            <Checkbox label="2">备案凭证</Checkbox>-->
          <!--          </CheckboxGroup>-->
          <RadioGroup v-model="postFrom.qualification_maintain_type" @on-change="changeType" :disabled="status != 3">
            <Radio label="1" style="margin-right: 40px;">注册证</Radio>
            <Radio label="2">备案凭证</Radio>
          </RadioGroup>
        </div>
      </div>
    </div>
    <div class="tableTitle upload">
      <span class="title fl"> 产品实物图片 </span>
      <span class="fl btnUpload finger">
        <FormUpload label="上传" @get-key="getKey" :multiple="true" @click="addFile" :httpUrl="httpUrl"></FormUpload>
      </span>
      <span class="title fl marignLeft20"> (需上传产品说明书，如果有条形码，需要上传条形码图片) </span>
    </div>
    <div class="imgBox">
      <ul>
        <li v-for="(item, i) in image_urls" :key="i">
          <img :src="item" class="normalImg finger" @click="lookUp(item)" />
          <img src="../../../assets/images/deleteLogistics.png" @click="deleteImg(i)" class="deleteImg" />
          <div>({{ i + 1 }})</div>
        </li>
      </ul>
    </div>
    <!--    图片查看-->
    <lookup-image v-if="imgStatus && lookUpImg" @closeImg="imgStatus = false" title="图片资料" :imgUrl="lookUpImg"></lookup-image>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import LookupImage from '@/components/lookupImage'
import FormUpload from '@/components/formUpload'
export default {
  name: 'productBaseInfo',
  components: {
    FromLabel,
    FormUpload,
    LookupImage,
  },
  data() {
    return {
      httpUrl:'/public/upload/file',
      lookUpImg: '',
      imgStatus: false,
      type: '',
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      postFrom: {
        image_urls: [],
        packing_size: {
          qty: '',
          unit: '',
          dose_unit: '',
        },
        unit: {
          qty: '',
          unit: '',
          dose_unit: '',
        },
      },
      factoryList: [],
      image_urls: [],
      status: '',
      count: 1,
      changeList: [],
      unitList: [],
    }
  },
  mounted() {
    this.status = this.$route.query.status
    this.getSearchProductFactory()
    this.$root.$on('productInfo', res => {
      if (this.count == 1) {
        this.count++
        this.postFrom = res
        this.postFrom.unit.qty = res.unit.qty ? res.unit.qty : ''
        this.postFrom.packing_size.qty = res.packing_size.qty ? res.packing_size.qty : ''
        this.changeList = res.changeList || []
        this.postFrom.qualification_maintain_type = this.postFrom.qualification_maintain_type + ''
        this.image_urls = this.postFrom.image_urls = res.image_file_url ? res.image_file_url.split(',') : []
        this.postFrom.image_file_name = res.image_files_name ? res.image_files_name.split(',') : []
        this.postFrom.image_files = res.image_files ? res.image_files.split(',') : []
        let arr = []
        if (this.postFrom.unit.unit || this.postFrom.unit.dose_unit || this.postFrom.packing_size.dose_unit) {
          arr = new Set([this.postFrom.unit.unit, this.postFrom.unit.dose_unit, this.postFrom.packing_size.dose_unit])
        }
        this.unitList = this.unitList.length > 0 ? [...new Set([...this.unitList, ...arr])] : [...new Set([...arr])]
      }
    })
    this.getUnit()
  },
  methods: {
    changeIpt1(e, num) {
      let count = 0
      if (e.target.value * 1 < 0) {
        count = 1
      } else {
        count = e.target.value.trim() * 1
      }
      if (num == 1) {
        this.postFrom.unit.qty = count
      } else {
        this.postFrom.packing_size.qty = count
      }
    },
    changeUnitUnit(val) {
      console.log(val)
      if (val) {
        this.postFrom.packing_size.unit = val
      } else {
        this.postFrom.packing_size.unit = ''
      }
    },
    getUnit() {
      this.$http.get(this.$api.productUnit, null, false).then(res => {
        res.data.forEach(item => {
          this.unitList.push(item.unit)
        })
        if (this.unitList.length > 0) {
          this.unitList = [...new Set([...this.unitList])]
        }
      })
    },
    openChangeUnit(status, obj, name, refName) {
      if (!status && !this.postFrom[obj][name]) {
        this.$refs[refName].query = ''
      }
    },
    handleCreateUnit(val) {
      this.unitList.push(val.trim())
    },
    // 更换资料维护
    changeType(e) {
      this.$root.$emit('changeType', e)
    },
    lookUp(item) {
      window.open(item)
    },
    // 产品厂家
    getSearchProductFactory() {
      this.$http.get(this.$api.ProductReviewFactory).then(res => {
        this.factoryList = res.data
      })
    },
    addFile() {},
    deleteImg(i) {
      this.image_urls.splice(i, 1)
      this.postFrom.image_files.splice(i, 1)
    },
    addType() {
      this.typeList.push(2)
    },
    removeType() {
      if (this.typeList.length === 1) return
      this.typeList.pop()
    },
    getKey(key, name, url) {
      this.image_urls.push(url)
      this.postFrom.image_files.push(key)
      this.postFrom.image_file_name.push(name)
    },
  },
}
</script>

<style scoped lang="less">
.upload {
  height: 36px;
  line-height: 36px;
}
.iviewIptWidth100 {
  width: 100px;
}
.marginRight18 {
  margin-right: 18px;
}
.xiegang {
  margin: 0 18px;
  color: #ccc;
}
.btnUpload {
  margin-left: 20px;
  display: inline-block;
  width: 76px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  background: #f0f8ff;
  color: #389afc;
  border: 1px solid #389afc;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      div {
        width: 171px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        color: #000;
      }
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.spin {
  margin-left: 20px;
  font-size: 14px;
  color: #525b6d;
}
.marignLeft20 {
  margin-left: 20px;
}
</style>
